<div class="welcome">
  <h1 class="mat-display-1 message">
    <p>Welcome</p>
    <p>We make design tools for us...</p>
    <p>and share them with you.</p>
  </h1>
  <h1 class="mat-display-1 message">
    <div class="entry">
      <span>Color Inverter for Adobe XD</span>
      <button mat-flat-button color="accent" [routerLink]="['colorinverter']">INFO</button>
    </div>
    <br />
    <p>New!</p>
    <div class="entry">
      <span>Material Design Icons for Adobe XD</span>
      <button mat-flat-button color="accent" [routerLink]="['mdi']">INFO</button>
    </div>
  </h1>
</div>
