<div class="colorinverter">
    <h1 class="mat-display-1">Color Inverter</h1>
    <div class="message">
        <p>
            Introducing the Color Inverter plugin for Adobe XD.
            <br /><br />
            <a href="https://adobe.com/go/xd_plugins_discover_plugin?pluginId=89929b9c">Plugin Install</a>
            <br /><br />
            At present, color inverter only inverts solid colors, and gradients are not affected.
            <br /><br />
            To invert a specific item or group of items, select them and then click on the "Color Inverter" from the plugin menu, or press
            "Ctrl+Shift+Alt+I" on Windows or "Cmd+Shift+Alt+I" on Mac to invert.
            <br />
            To invert the entire document (including color assets) invoke the plugin as above
            without a selection.
        </p>
        <p>
            To report problems, please use our <a href="https://github.com/uxerscode/inverter/issues">github page</a>, or email us at <a href="mailto:uxerscode@gmail.com">uxerscode@gmail.com</a>.
        </p>
        <button mat-flat-button color="accent" [routerLink]="['']">HOME</button>
    </div>
</div>