<div class="mdipage">
    <h1 class="mat-display-1">Material Design Icons</h1>
    <div class="message">
        <p>
            Material Design Icons plugin for Adobe XD.
            <br /><br />
            <a href="https://adobe.com/go/xd_plugins_discover_plugin?pluginId=15d3ddbb">Plugin Install</a>
            <br /><br />
            Enter the search criteria and press [enter] or click the search button.
            <br />
            Click on the icon you would like to add to your document, and the icon will be added center of the current document.
        </p>
        <p>
            To report problems, please use our <a href="https://github.com/uxerscode/mdi-xd-plugin/issues">github page</a>, or email us at <a href="mailto:uxerscode@gmail.com">uxerscode@gmail.com</a>.
        </p>
        <button mat-flat-button color="accent" [routerLink]="['']">HOME</button>
    </div>
</div>